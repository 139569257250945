import * as React from 'react';
import './../styles/styles.scss';
import Header from '../components/Header';

const IndexPage = () => {
  return (
    <main>
      <Header />
    </main>
  );
};

export default IndexPage;
