import * as React from 'react';

const Header = () => {
  return (
    <header>
      <title>Home Page</title>
      <h1>
        Mastered<span className="logo-stop">.</span>
      </h1>
      <h2>An exciting announcement is coming soon!</h2>
      <p>
        Due to high demand we're currently not taking on any additional clients,
        but stay tuned we have an exciting update coming in the next few weeks.
      </p>
      {/* <a href="https://quote.mastered.ie/?ref=mastered">Get a quote</a> */}
    </header>
  );
};

export default Header;
